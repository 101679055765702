import React, { CSSProperties, ReactNode } from 'react';

interface CircleData{
    width?: string,
    height?: string,
    className?: string,
    style?: CSSProperties,
    children?: ReactNode,
    borderWidth?: string,
    backgroundColor?: string
}

const Circle: React.FC<CircleData> = ({width = "36vw", height = "36vw", className, style, borderWidth = "15px", backgroundColor = "white", children}) => {
    const defaultStyles: CSSProperties = {
        width: width,
        height: height,
        border: `solid ${borderWidth} #6EC1E4`,
        backgroundColor: backgroundColor,
        borderRadius: "100%",
    };

    const mergedStyles: CSSProperties = { ...defaultStyles, ...style };

    return (
        <div className={className} style={mergedStyles}>
            {children}
        </div>
    )
}

export default Circle;