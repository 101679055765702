import React from "react";
import { Link } from "react-router-dom";
import Markdown from "markdown-to-jsx";
import { months } from "../../util/Constant";

import "./BlogItem.css";

interface BlogData{
    id: number,
    creatorName: string,
    imageUrl: string,
    createdAt: Date,
    title: string,
    description: string,
}

const BlogItem: React.FC<BlogData> = (props) => {
  const date = new Date(props.createdAt);
  const displayDate = months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
  return (
    <div className="post">
      <Link to={`/blogPost/${props.id}`} style={{ textDecoration: "none" }}>
        <div className="postImg">
          <img src={props.imageUrl} alt={props.title} />
        </div>

        <div className="postInfo">
          <span className="postTitle">{props.title}</span>
          <div className="postAuthors">
            <span className="postDate">{displayDate}</span>
          </div>
          <Markdown className="postDesc">{props.description}</Markdown>
        </div>
      </Link>
    </div>
  );
}

export default BlogItem;
