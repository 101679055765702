import React, {useRef} from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { motion, useTransform, MotionValue } from 'framer-motion';
import './StackCard.css';

interface CardProps{
    id: number,
    title: string,
    description: string,
    href: string,
    isPhone: boolean,
    img1: any,
    img2: any,
    progress: MotionValue<number>,
}

const StackCard: React.FC<CardProps> = (props) => {
    const cardContainer = useRef(null);
    const targetScale = 1 - ((4 - props.id) * 0.05);
    const range = [props.id * 0.25, 1];
    const scale = useTransform(props.progress, range, [1, targetScale]);

    return (
        <div className='card' ref={cardContainer}>
            <motion.div className='card__content' style={{top: `${props.id * 30}px`, scale}}>
                <div className='descContainer'>
                    <a className='gButton' href={props.href} target='_blank' rel="noreferrer">GitHub <FontAwesomeIcon
                        icon={faArrowUpRightFromSquare}
                        size="1x"
                        style={{marginLeft: 5}}
                    /></a>
                    <p style={{color: 'black', fontSize: "2vw", fontWeight: 'bold', marginBlockStart: 5, marginBlockEnd: 5}}>{props.title}</p>
                    <p style={{color: 'black', fontSize: "1vw"}}>{props.description}</p>
                </div>
                <div className='imageContainer'>
                    <img className={props.isPhone ? 'phoneImage' : 'webImage'} style={{transform: props.isPhone ? "translate(-55%, -5%)" : "translate(-10%, -70%)"}} src={props.img1} alt=''/>
                    <img className={props.isPhone ? 'phoneImage' : 'webImage'} style={{transform: props.isPhone ? "translate(55%, 20%)" : "translate(10%, 0%)", paddingLeft: props.isPhone ? 30 : 0}} src={props.img2} alt=''/>
                </div>
            </motion.div >
        </div>
    )
}

export default StackCard;