import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { AuthContext } from "../../../context/auth-context";
import './NavLinks.css';

function NavLinks(){
  const auth = useContext(AuthContext);
    return (
      <ul className="nav-links">
        <li>
          <NavLink to="/" end>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/work" end>
            Works
          </NavLink>
        </li>
        <li>
          <NavLink to="/Go-Blog" end>
            Go-blog
          </NavLink>
        </li>
        {!auth.isLoggedIn && (
          <li>
            <NavLink to="/user/login" end>
              Login
            </NavLink>
          </li>
        )}
        {auth.isLoggedIn && (
          <li>
            <button onClick={auth.logout}>LOGOUT</button>
          </li>
        )}
      </ul>
    );
};

export default NavLinks;