import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom"
import './App.css';
import MainNavigation from './components/navigations/MainNavigation/MainNavigation';
import ScrollToTop from './components/ScrollToTop';
import Post from './pages/Post/Post';
import { AuthContext } from "./context/auth-context";
import { useAuth } from "./hooks/auth-hook";
import Footer from './components/Footer/Footer';

import Home from './pages/Home/Home';
import Blog from './pages/Blog/Blog';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import Work from './pages/Work/Work';
// import About from './pages/About/About';

function App() {
  const {token, login, logout, userId, isAdmin} = useAuth();
  
  let routes;
  if(token){
    routes = (
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/work" element={<Work/>} />
        <Route path="/Go-Blog" element={<Blog/>} />
        <Route path="/blogPost/:blogId" element={<Post/>} />
      </Routes>
    );
  }else{
    routes = (
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/work" element={<Work/>} />
        <Route path="/Go-Blog" element={<Blog/>} />
        <Route path="/user/login" element={<Login/>} />
        <Route path="/user/register" element={<Register/>} />
        <Route path="/blogPost/:blogId" element={<Post/>} />
      </Routes>
    );
  }
   

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        isAdmin: isAdmin,
        login: login,
        logout: logout,
      }}
    >
      <Router>
        <ScrollToTop/>
        <div style={{backgroundColor: "#194569", minHeight: "100vh", paddingTop: "4rem"}}>
          <MainNavigation/>
          <main>{routes}</main>
          <Footer/>
        </div>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
