import React, {FC} from 'react';
import profileImg from '../../Assets/profile_pic.png';
import './Badge.css';

interface BadgeProps{
    logoImg: any;
    text: string;
    onClick: () => void;
}

const Badge: FC<BadgeProps> = ({logoImg, text, onClick}) => {
    return (
        <div className='badge'>
            <div className='badge_pic'>
                <img src={profileImg} alt=''/>
            </div>
            <div className='badge_description'>
                <div className='circle_fiverr'>
                    <img src={logoImg} alt=''/>
                </div>
                <h2>Seller</h2>
                <h3>Programming & Tech</h3>
                <button onClick={onClick}>{text}</button>
            </div>
        </div>
    )
}

export default Badge;