import React, { CSSProperties, ReactNode } from "react";

import "./Card.css";

interface CardProps{
    className?: string,
    style?: CSSProperties,
    id?: string,
    children?: ReactNode
}

const Card: React.FC<CardProps> = (props) => {
  return (
    <div className={`card2 ${props.className}`} style={props.style} id={props.id}>
      {props.children}
    </div>
  );
};

export default Card;
