import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

import "./Button.css";

interface ButtonProps {
  size?: number;
  inverse?: boolean;
  danger?: boolean;
  register?: boolean;
  href?: string;
  to?: string;
  exact?: boolean;
  type?: 'submit' | 'reset' | 'button' | undefined;
  onClick?: () => void;
  disabled?: boolean;
  children: ReactNode;
}

const Button: React.FC<ButtonProps> = (props) => {
  if (props.href) {
    return (
      <a
        className={`button button--${props.size || "default"} ${
          props.inverse && "button--inverse"
        } ${props.danger && "button--danger"} ${
          props.register && "button--register"
        }`}
        href={props.href}
      >
        {props.children}
      </a>
    );
  }
  if (props.to) {
    return (
      <Link
        to={props.to}
        className={`button button--${props.size || "default"} ${
          props.inverse && "button--inverse"
        } ${props.danger && "button--danger"} ${
          props.register && "button--register"
        }`}
      >
        {props.children}
      </Link>
    );
  }
  return (
    <button
      className={`button button--${props.size || "default"} ${
        props.inverse && "button--inverse"
      } ${props.danger && "button--danger"} ${
        props.register && "button--register"
      }`}
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export default Button;