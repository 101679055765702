import React from 'react';
import Circle from '../Shapes/Circle';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';

//image
import img1 from '../../Assets/portfolio_pp.png';
import { img2, img3, img4, img5, img6, img7, img8, img9, img10 } from '../../util/Constant';

import "./Header.css";

function Header(){
    const navigate = useNavigate();
    return (
    <div className='mainContainer'>
        <div className='textContainer'>
            <div>
                <h1 className='headerTitleLg'>Hi, I'm Bryan. <br/> Software Engineer <br/> in <span style={{color: "#6EC1E4"}}>United States</span></h1>
                <h1 className='headerTitleSm'> I'm actively seeking opportunities in web and mobile development, <br/> fueled by a passion for crafting meaningful digital experiences. <br/> My expertise extends to a wide array of technologies, and I'm <br/> always eager to learn and adapt to new challenges.</h1>
                <div className='buttonContainer'>
                    <a href={'./Bryan_Christiano.pdf'} download="Bryan_Resume" target='_blank' rel="noreferrer">
                        <button className='cv_button'>Resume 
                        <FontAwesomeIcon
                            icon={faDownload}
                            size="1x"
                            style={{marginLeft: 5}}
                        />
                        </button>
                    </a>
                    <button onClick={() => navigate('/Go-Blog')} className='blog_button'>Go-Blog</button>
                </div>
            </div>
            {/* Two button here, one download cv and other to blog page */}
        </div>
            
        <div className='profileContainer'>
            {/* Largest Circle */}
            {/* <div style={{position: 'relative', overflow: 'hidden', width: "100%", height: "100%"}}> */}
            <Circle className='circle'>
                
                <Circle className='cplus' width="2.5vw" height="2.5vw" borderWidth='10px'>
                    <img className='imageSize' src={img7} alt=''/>
                </Circle>
                <Circle className='dart' width="2.5vw" height="2.5vw" borderWidth='10px'>
                    <img className='imageSize' src={img8} alt=''/>
                </Circle>
                <Circle className='css' width="2.5vw" height="2.5vw"  borderWidth='10px'>
                    <img className='imageSize' src={img9} alt=''/>
                </Circle>
                <Circle className='html' width="2.5vw" height="2.5vw"  borderWidth='10px'>
                    <img className='imageSize' src={img10} alt=''/>
                </Circle>
                <img className='profilePic' src={img1} alt=''/>
            </Circle>

            {/* Medium circle */}
            <Circle className='react' width="6vw" height="6vw" borderWidth='10px'>
                <img className='imageSize' src={img2} alt=''/>
            </Circle>
            <Circle className='javascript' width="6vw" height="6vw" borderWidth='10px'>
                <img className='imageSize' src={img3} alt=''/>
            </Circle>
            <Circle className='nodejs' width="6vw" height="6vw" borderWidth='10px'>
                <img className='imageSize' src={img4} alt=''/>
            </Circle>
            <Circle className='python' width="6vw" height="6vw" borderWidth='10px'>
                <img className='imageSize' src={img5} alt=''/>
            </Circle>
            <Circle className='mysql' width="6vw" height="6vw" borderWidth='10px'>
                <img className='imageSize' src={img6} alt=''/>
            </Circle>
        </div>
    </div>
    )
}

export default Header;