import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons';

import "./Footer.css";

function Footer(){
    return (
    <div className="main-footer">
      <div className="container">
        <div className="footer-table" style={{justifyContent: 'space-between', paddingBottom: "2.5vw"}}>
          {/* Column1 */}
          <div className="col" style={{flex: 2, marginRight: 15}}>
            <h4 className='titleText'>About Becko ( Website )</h4>
            <ul className="list-unstyled">
              <li>
                Bryan designed and developed this website using React, Express, Node, and MongoDB technologies. It serves as a platform to showcase my software engineering journey and share insights through my blog. I'm actively seeking opportunities, feel free to contact me using the contact information on the right.
              </li>
            </ul>
          </div>
          {/* Column2 */}
          <div className="col" style={{flex: 1}}>
            <h4 className='titleText'>Contact Information</h4>
            <ul className="list-unstyled">
              <li>PHONE <br/> +1 (669) 204-1164</li>
              <li>EMAIL <br/> bryantrack1@gmail.com</li>
            </ul>
          </div>
          {/* Column3 */}
          <div className="col" style={{flex: 1}}>
            <h4 className='titleText'>Social Media</h4>
            <ul className="list-unstyled">
              <a style={{textDecoration: "none", color: 'white'}} href="https://github.com/selimut123" target='_blank' rel="noreferrer">
                <FontAwesomeIcon className='icon-style' icon={faGithub}/>
              </a>
              <a style={{textDecoration: "none", color: 'white', marginLeft: 10}} href="https://www.linkedin.com/in/christiano-bryan/" target='_blank' rel="noreferrer">
                <FontAwesomeIcon className='icon-style' icon={faLinkedin}/>
              </a>
              <a style={{textDecoration: "none", color: 'white', marginLeft: 10}} href="https://twitter.com/Becko420" target='_blank' rel="noreferrer">
                <FontAwesomeIcon className='icon-style' icon={faXTwitter}/>
              </a>
            </ul>
          </div>
        </div>
        <div className="credit">
            <p>&copy;{new Date().getFullYear()} Becko | Designed by Bryan</p>
        </div>
      </div>
    </div>
    )
}

export default Footer;