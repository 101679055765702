import React, { useEffect, useState, ReactNode } from "react";
import "./Modal.css";

interface modalProps{
    title: string,
    show: boolean,
    onClose: (params: boolean) => void,
    children: ReactNode,
}

const Modal: React.FC<modalProps> = ({title, show, onClose, children}) => {
  const [show1, setShow1] = useState(false);

  const closeHandler = () => {
    setShow1(false);
    onClose(false);
  };

  useEffect(() => {
    setShow1(show);
  }, [show]);

  return (
    <div
      style={{
        visibility: show1 ? "visible" : "hidden",
        opacity: show1 ? "1" : "0"
      }}
      className="overlay"
    >
      <div className="popup">
        <h2>{title}</h2>
        <span className="close" onClick={closeHandler}>
          &times;
        </span>
        <div className="content">{children}</div>
      </div>
    </div>
  );
};
export default Modal;