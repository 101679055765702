import React, {useRef} from 'react';

import Header from '../../components/Header/Header';
import finImg1 from '../../Assets/finApp_9.png';
import finImg2 from '../../Assets/finApp_11.png';
import foodImg1 from '../../Assets/ODFDS_1.png';
import foodImg2 from '../../Assets/ODFDS_2.png';
import neatImg1 from '../../Assets/neat_4.png';
import neatImg2 from '../../Assets/neat_5.png';
import adminImg1 from '../../Assets/admin_4.png';
import adminImg2 from '../../Assets/admin_5.png';
import StackCard from '../../components/StackCard/StackCard';
import { useNavigate } from 'react-router-dom';
import {useScroll} from 'framer-motion';
import "./Home.css";

function Home(){
    const navigate = useNavigate();
    const cardContainer = useRef(null);
    const {scrollYProgress} = useScroll({
        target: cardContainer,
        offset: ['start start', 'end end']
    })
    return (
        <div className='main'>
            <Header/>
            <div className='projects'>
                <div style={{height: "3vw", marginBottom: 80}}>
                    <h1 style={{textAlign: 'center', fontSize: "2vw", width: "100%", color: 'white'}}>Featured Works</h1>
                    <div className='arrow'>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <ul id="cards" ref={cardContainer}>
                    <StackCard
                        id={0}
                        title="FinApp Mobile Application"
                        description="A personal financial management app, empowering users to calculate and analyze their monthly spending."
                        href="https://github.com/selimut123/finApp"
                        isPhone={true}
                        img1={finImg1}
                        img2={finImg2}
                        progress={scrollYProgress}
                    />
                    <StackCard
                        id={1}
                        title="On-Demand Food Delivery Service"
                        description="Transform restaurant's delivery service, with the help of nearest drivers for swift and precise food deliveries."
                        href="https://github.com/selimut123/CS160-Project-Team4"
                        isPhone={false}
                        img1={foodImg1}
                        img2={foodImg2}
                        progress={scrollYProgress}
                    />
                    <StackCard
                        id={2}
                        title="Neat Todo List"
                        description="A Todo List application to manage your tasks and stay organized."
                        href="https://github.com/selimut123/TODO-LIST"
                        isPhone={true}
                        img1={neatImg1}
                        img2={neatImg2}
                        progress={scrollYProgress}
                    />
                    <StackCard
                        id={3}
                        title="E-commerce Admin Panel"
                        description="An E-commerce management system that allowed customer service with the previledge to organize product."
                        href="https://github.com/selimut123/Inventory-system"
                        isPhone={false}
                        img1={adminImg1}
                        img2={adminImg2}
                        progress={scrollYProgress}
                    />
                </ul>
                <div style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>
                    <div className='arrow2'>
                        <span></span>
                        <span></span>
                    </div>
                    <button className='viewmore_button' onClick={() => navigate('/work')}>
                        View More
                    </button>
                    <div className='arrow3'>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
            
            <div className='blogs'>
                <span style={{textAlign: 'left', fontSize: "2vw", color: 'white', fontWeight: 'bold', marginBottom: 10}}>GO CHECK MY <span style={{color:"#6EC1E4"}} >BLOGS</span></span>
                <span style={{textAlign: 'left', fontSize: "1.5vw", color: 'white', fontWeight: 'bold', marginBottom: 10}}>I WRITE WHAT I LEARN</span>
                <span style={{textAlign: 'left', fontSize: "1.2vw", color: '#61CE70', fontWeight: 'bold', marginBottom: 10}}>Thoughts, Educations, News, Software, Technology, Entrepreneurship, Finance, Business.</span>
                <span style={{textAlign: 'left', fontSize: "1vw", color: 'white', fontWeight: 'bold', marginBottom: 30}}>I write to remember and focus, explore my collection of insights on my blog.</span>
                <button className='blog_button' onClick={() => navigate('/Go-Blog')}>Go-Blog</button>
            </div>
        </div>
    );
}

export default Home;