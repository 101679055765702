import React from 'react';

import "./Pagination.css";

interface PaginProps {
    postsPerPage: number,
    totalPosts: number,
    paginate: (arg: number) => void
}

const Pagination: React.FC<PaginProps> = ({postsPerPage, totalPosts, paginate}) => {
    const pageNumbers = [];

    for(let i = 1; i <= Math.ceil(totalPosts/postsPerPage); i++){
        pageNumbers.push(i);
    }

    return (
    <nav>
        <ul className='pagination'>
            {pageNumbers.map(number => (
                <li key={number} className='page-item'>
                    <button onClick={() => paginate(number)} className='page-link'>
                        {number}
                    </button>
                </li>
            ))}
        </ul>
    </nav>
    )
}

export default Pagination;