import img2 from '../Assets/prof_7.png';
import img3 from '../Assets/prof_5.png';
import img4 from '../Assets/prof_13.png';
import img5 from '../Assets/prof_22.png';
import img6 from '../Assets/prof_10.png';
import img7 from '../Assets/prof_16.png';
import img8 from '../Assets/prof_14.png';
import img9 from '../Assets/prof_3.png';
import img10 from '../Assets/prof_4.png';
import img11 from '../Assets/prof_9.png';
import img12 from '../Assets/prof_24.png';
import img13 from '../Assets/prof_25.png';
import img14 from '../Assets/prof_26.png';
import img15 from '../Assets/prof_27.png';
import img16 from '../Assets/prof_28.png';

export {
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
}

export const months = [ "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December" ];

export const experiences = [
    { 
        id: "1",
        name: "KebunPintar" ,
        location: "Jakarta, Indonesia",
        position: "Software Development Intern",
        date: "Sep 2021 - Dec 2021",
        info: (<><li>Developed user-friendly components using Dart, enhancing overall UI by providing intuitive user selection options, smooth animations, and seamless integration with data sources
</li><li>Improved application performance, and reduced boilerplate code by 50% by implementing efficient state management using GetX 
libraries in Flutter, leading to a more maintainable and scalable codebase</li><li>Addressed and resolved unexpected API issues, leveraging various testing methodologies appropriately, including unit testing, 
integration testing, as well as end-to-end testing, resulting in improved data accessibility and system interoperability</li><li>Developed and applied UI components (search bar, multi-field form elements) in Flutter to be reused across the Web Application</li></>),
    },
    { 
        id: "2", 
        name: "PT Putra Mulia Telecommunication" ,
        location: "Jakarta, Indonesia",
        position: "Information Technology Intern",
        date: "Jul 2021 - Aug 2021",
        info: (<><li> Performed on-site visits to help the company set up new laptops in domain-joined and connected necessary applications using I-Desk.</li>
        <li>Installed, serviced, and upgraded over 20 Windows laptops. Also, regularly inspect and replace broken laptop parts.</li>
        <li>Communicated with colleagues to assess and troubleshoot client issues.</li></>),
    }
];

export const COLORS = {
    primary: "#6EC1E4",
    secondary: "#61CE70",
    light_blue: "#B7D0E1",
    dark_blue: "#194569",
}