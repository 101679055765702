import React, {useRef} from 'react';

import Card from '../../components/Card/Card';
import finImg1 from '../../Assets/finApp_1.png';
import neatImg1 from '../../Assets/neat_1.png';
import foodImg1 from '../../Assets/ODFDS_3.png';
import kebunImg1 from '../../Assets/KebunPintar_2.png';
import blogImg1 from '../../Assets/goblog_1.png';
import budiImg1 from '../../Assets/budi_3.png';
import forumImg1 from '../../Assets/forum_1.png';
import adminImg1 from '../../Assets/admin_3.png';
import wrdImg1 from '../../Assets/wordpress_1.png';
import wrdImg2 from '../../Assets/wordpress_2.png';
import wrdImg3 from '../../Assets/wordpress_3.png';
import wrdImg4 from '../../Assets/wordpress_4.png';
import wrdImg5 from '../../Assets/wordpress_5.png';
import fiverrImg from '../../Assets/fiverr_logo.png';
import upworkImg from '../../Assets/upwork_logo.png';
import fiverrProf from '../../Assets/freelance_2.png';
import upworkProf from '../../Assets/freelance_1.png';
import './Work.css';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Badge from '../../components/Badge/Badge';

function Work(){
    const arrowRef = useRef<Slider>(null);
    var settings = {
        className: "center",
        centerMode: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows : false,
        responsive: [
        {
            breakpoint: 990,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            centerMode : false
            }
        },
        {
            breakpoint: 600,
            settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
            centerMode : false
            }
        },
        {
            breakpoint: 480,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode : false
            }
        }
        ]
    };

    const redirectFiver = () => {
        window.location.href = 'https://www.fiverr.com/bryanchristiano';
    }

    const redirectUpwork = () => {
        window.location.href = 'https://www.upwork.com/freelancers/bryanbecko';
    }

    return (
        <div className='workContainer'>
            <div className='freelance'>
                <h1 style={{color: 'white', fontSize: 40}}><span style={{color: '#6EC1E4'}}>Free</span>lance</h1>
                <div className='freelance_cotaniner'>
                    <div className='upwork'>
                        <img style={{width: '100%', maxHeight: 420}} src={upworkProf} alt=''/>
                        <Badge
                            logoImg={upworkImg}
                            text='Check out my Profile'
                            onClick={redirectUpwork}
                        />
                    </div>
                    <div className='fiverr'>
                        <img style={{width: '100%', maxHeight: 420}} src={fiverrProf} alt=''/>
                        <Badge
                            logoImg={fiverrImg}
                            text='Check out my Gigs'
                            onClick={redirectFiver}
                        />
                    </div>
                </div>
            </div>
            <div className='wordpress'>
                <h1 style={{color: 'white', fontSize: 40}}><span style={{color: '#6EC1E4'}}>Word</span>Press</h1>
                <h3 style={{color: 'white', fontSize: 20}}>I can build you wordpress website for your businesses, fast and cheap, contact me now!</h3>
                <div style={{position: 'relative'}}>
                    <Slider ref={arrowRef} {...settings}>
                            <div className='wordpress-card'>
                                <img src={wrdImg1} alt=''/>
                                <div className='disc'>
                                    <h1>Business</h1>
                                </div>
                            </div>
                            <div className='wordpress-card'>
                                <img src={wrdImg2} alt=''/>
                                
                                <div className='disc'>
                                    <h1>Portfolio</h1>
                                </div>
                            </div>
                            <div className='wordpress-card'>
                                <img src={wrdImg3} alt=''/>
                                
                                <div className='disc'>
                                    <h1>Business</h1>
                                </div>
                            </div>
                            <div className='wordpress-card'>
                                <img src={wrdImg4} alt=''/>
                                
                                <div className='disc'>
                                    <h1>E-commerce</h1>
                                </div>
                            </div>
                            <div className='wordpress-card'>
                                <img src={wrdImg5} alt=''/>
                                
                                <div className='disc'>
                                    <h1>Blog</h1>
                                </div>
                            </div>
                    </Slider>
                    <div className='buttonContainer2'>
                        <button onClick={() => {if(arrowRef.current)arrowRef.current.slickNext()}} className='back'><FontAwesomeIcon size='2x' icon={faChevronRight}/></button>
                        <button onClick={() => {if(arrowRef.current)arrowRef.current.slickPrev()}} className='next'><FontAwesomeIcon size='2x' icon={faChevronLeft}/></button>
                    </div>
                </div>
            </div>
            <h1 style={{color: 'white', fontSize: 40, textAlign: 'center'}}>Works Gallery</h1>
            <div className='collectionsContainer'>
                <div className='subContainer'>
                    <Card className='workCard'>
                        <img src={foodImg1} alt=''/>
                    </Card>
                    <Card className='workCard'>
                        <img src={kebunImg1} alt=''/>
                    </Card>
                    <Card className='workCard'>
                        <img src={neatImg1} alt=''/>
                    </Card>
                </div>
                <div className='subContainer'>
                    <Card className='workCard'>
                        <img src={finImg1} alt=''/>
                    </Card>
                    <Card className='workCard'>
                        <img src={blogImg1} alt=''/>
                    </Card>
                </div>
                <div className='subContainer'>
                    <Card className='workCard'>
                        <img src={forumImg1} alt=''/>
                    </Card>
                    <Card className='workCard'>
                        <img src={adminImg1} alt=''/>
                    </Card>
                    <Card className='workCard'>
                        <img src={budiImg1} alt=''/>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default Work;