import React, {useState, createRef, useEffect, useContext} from 'react';
import Modal from '../../components/Modal/Modal';
import Markdown from 'markdown-to-jsx';
import { useForm } from '../../hooks/form-hook';
import Button from '../../components/FormElements/Button/Button';
import Input from '../../components/FormElements/Input/Input';
import BlogItem from '../../components/BlogItem/BlogItem';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';
import {
  VALIDATOR_REQUIRE,
} from "../../util/validators";

import "./Blog.css";

function Blog(){
  const [formState, inputHandler] = useForm(
    {
      title: {
        value: "",
        isValid: false,
      },
      description: {
        value: "",
        isValid: false,
      },
      image: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  const [visibility, setVisibility] = useState(false);
  const [file, setFile] = useState();
  const [previewUrl, setPreviewUrl] = useState<any>();
  const [isValid, setIsValid] = useState<boolean>();
  const filePickerRef = createRef<any>();
  const [activeTab, setActiveTab] = useState(true);
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedBlogs, setLoadedBlogs] = useState<any>([]);

  const popupCloseHandler = (e : boolean) => {
      setVisibility(e);
  };

  const handleClick= (index: boolean) => {
      setActiveTab(index);
  }

  useEffect(()=> {
    if(!file){
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  },[file]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BASE_URL}/api/blog`
        );
        let sortedBlogs = responseData.blogs.sort((a: { createdAt: Date; }, b: { createdAt: Date; }) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        setLoadedBlogs(sortedBlogs);
      } catch (err) {}
    };
    fetchBlogs();
  }, [sendRequest]);

  function pickedHandler(event: any){
    let pickedFile: any;
    let fileIsValid: any = isValid;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);
      setIsValid(true);
      fileIsValid = true;
    } else {
      setIsValid(false);
      fileIsValid = false;
    }
    inputHandler("image", pickedFile, fileIsValid);
  }
  
  function pickImageHandler(){
    filePickerRef.current.click();
  }

  async function SubmitHandler(event: any) {
      event.preventDefault();
      try {
      const formData= new FormData();
      formData.append("title", formState.inputs.title.value);
      formData.append("description", formState.inputs.description.value);
      formData.append("creator", auth.userId);
      formData.append("image", formState.inputs.image.value);
      await sendRequest(
        `${process.env.REACT_APP_BASE_URL}/api/blog/write`,
        "POST",
        formData,
        {
          Authorization: "Bearer: " + auth.token,
        }
      );
      setVisibility(false);
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BASE_URL}/api/blog`
      );
       let sortedBlogs = responseData.blogs.sort((a: { createdAt: Date; }, b: { createdAt: Date; }) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      setLoadedBlogs(sortedBlogs);
    } catch (err) {}
  }
    
  return (
    <React.Fragment>
      <ErrorModal
      onCancel={clearError}
      header="An Error Occurred!"
      show={!!error}
      footer={<Button onClick={clearError}>Okay</Button>}
    >
      <p>{error}</p>
    </ErrorModal>

    {isLoading && (
      <div className="center">
        <LoadingSpinner />
      </div>
    )}

    <div style={{width: "100%"}}>
      {!isLoading && auth.isLoggedIn && auth.isAdmin && <div style={{paddingLeft: "10vw", marginBottom: 20}}>
        <h1 style={{color: 'white'}}>Welcome Back Admin!</h1>
        <button onClick={(e) => setVisibility(!visibility)}>Create</button>
        <Modal
          onClose={popupCloseHandler}
          show={visibility}
          title="Create Post"
        >
          <form className="writeForm" onSubmit={SubmitHandler}>
            <div className="writeImg">
            <button type='button' onClick={pickImageHandler} style={{cursor: "pointer"}}>
              {previewUrl && <img src={previewUrl} alt="" />}
              {!previewUrl && <div>Pick Image</div>}
            </button>
            </div>
            <input
              id={"image"}
              ref={filePickerRef}
              style={{ display: "none" }}
              type="file"
              accept=".jpg,.png,.jpeg"
              onChange={pickedHandler}
            />
              <div className="writeInput">
                <Input
                  id="title"
                  element="input"
                  type="text"
                  placeholder="Title"
                  validators={[VALIDATOR_REQUIRE()]}
                  errorText="Please enter your title here."
                  onInput={inputHandler}
                />
              </div>

              <div className='tabBox2'>
                  <div className="tab2">
                      <button type='button' className="tablinks" style={{backgroundColor: activeTab ? "#ddd" : "#f1f1f1" }} onClick={() => handleClick(true)}>Edit</button>
                      <button type='button' className="tablinks" style={{backgroundColor: !activeTab ? "#ddd" : "#f1f1f1" }} onClick={() => handleClick(false)}>Preview</button>
                  </div>

                  <div>
                      {activeTab ? <div className="writeInput">
                          <Input
                              id="description"
                              element="textarea"
                              type="text"
                              placeholder="Tell your story"
                              rows={18}
                              validators={[VALIDATOR_REQUIRE()]}
                              errorText="Please enter your description here."
                              onInput={inputHandler}
                              initialValue={formState.inputs.description.value}
                              initialValid={formState.inputs.description.isValid}
                          />
                  </div> :<div style={{overflow: "hidden", height: "21vw", padding: 10}}>
                          <div style={{height: "100%", width: "100%", overflow: "auto"}}>
                              <Markdown>
                                  {formState.inputs.description.value}
                              </Markdown>
                          </div>
                      </div>
                      }
                  </div>
              </div>
            {/* button to submit */}
            <div className="writeSubmit">
              <Button type="submit" size={25} disabled={!formState.isValid}>
                Save
              </Button>
            </div>
          </form>
        </Modal>
      </div>}
      {!isLoading && loadedBlogs && <div style={{paddingLeft: "10vw", paddingRight: "10vw", marginTop: "4vw"}}>
        <div className='textContainer'>
          <span className='textstyle--1'>GO CHECK MY <span style={{color:"#6EC1E4"}} >BLOGS</span></span>
          <span className='textstyle--2'>Thoughts, Educations, News, Software, Technology, Entrepreneurship, Finance, Business.</span>
          <span className='textstyle--3'>I started writing online in 2023, mostly about Tech, Finance, Entrepreneurship. I started writing to record what I had learned and studied. Feel free to read my blog, your thoughts and feedback are highly appreciated!</span>
        </div>
        <h1 className="LatestPost">All Posts</h1>
        <div className="AllBlogs">
          {loadedBlogs.map((blog: any, index: number) =>
            (
              <BlogItem
                key={blog.id}
                id={blog.id}
                imageUrl={blog.imageUrl}
                createdAt={blog.createdAt}
                title={blog.title}
                description={blog.description}
                creatorName={blog.creatorName}
              />
            )
            )}
        </div>
      </div>}
  </div>
    </React.Fragment>
  
  );
}

export default Blog;